// // import { useState, useEffect } from "react";
// // import { auth } from "../config/firebase";
// // import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// // import { useNavigate } from "react-router-dom";
// // import { doc, getDoc, setDoc } from "firebase/firestore";
// // import { getFirestore } from "firebase/firestore";
// // import { getAuth } from "firebase/auth";
// // import logo from "../images/thirdeye.png";
// // import styled from "styled-components";

// // const Container = styled.div`
// //   font-family: sans-serif;
// //   background-color: #f7fafc; /* bg-gray-100 */
// //   height: 100vh;
// //   overflow: hidden;
// //   padding-bottom: 150px;
// // `;

// // const Header = styled.header`
// //   background-color: #fff; /* bg-white */
// //   border-bottom: 1px solid #e2e8f0; /* border-gray-200 */
// //   padding: 0.625rem 1rem; /* px-4 py-2.5 */
// //   @media (min-width: 1024px) {
// //     padding: 0.625rem 1.5rem; /* lg:px-6 */
// //   }
// //   &.dark {
// //     background-color: #2d3748; /* dark:bg-gray-800 */
// //   }
// // `;

// // const HeaderContent = styled.div`
// //   display: flex;
// //   flex-wrap: wrap;
// //   justify-content: space-between;
// //   align-items: center;
// // `;

// // const Logo = styled.img`
// //   height: 2.75rem; /* h-11 */
// //   width: auto;
// //   align-self: center;
// // `;

// // const MainContent = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   justify-content: center;
// //   align-items: center;
// //   height: 80vh; /* Adjust height for better view on smaller screens */
// //   max-width: 90%; /* Ensure it doesn't overflow on smaller screens */
// //   width: 24rem; /* Default width */
// //   margin: 0 auto;
// //   margin-top: 0.5rem; /* mt-2 */
// //   background-color: #f7fafc; /* bg-gray-100 */
// //   position: relative;

// //   @media (max-width: 640px) {
// //     width: 90%; /* Adjust width for smaller screens */
// //     height: 70vh; /* Adjust height for smaller screens */
// //   }

// //   @media (max-width: 360px) {
// //     width: 95%; /* Further adjust width for very small screens */
// //     height: 60vh; /* Further adjust height for very small screens */
// //   }
// // `;

// // const BlueBox = styled.div`
// //   width: 80%; /* w-4/5 */
// //   background-color: #3b82f6; /* bg-custom-blue */
// //   padding: 1.25rem; /* p-5 */
// //   border-radius: 0.5rem; /* rounded-lg */
// //   color: #fff;
// //   text-align: center;
// //   font-family: "Inter", sans-serif;

// //   @media (max-width: 640px) {
// //     width: 90%; /* Adjust width for smaller screens */
// //   }

// //   @media (max-width: 360px) {
// //     width: 95%; /* Further adjust width for very small screens */
// //   }
// // `;

// // const FormContainer = styled.div`
// //   width: 80%; /* w-4/5 */
// //   background-color: #fff;
// //   padding: 1.25rem; /* p-5 */
// //   border-radius: 0.5rem; /* rounded-lg */
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
// //   margin-top: 1.25rem; /* mt-5 */
// //   transition: all 0.5s ease-in-out; /* transition duration-500 ease-in-out */
// //   transform: translateY(0);

// //   @media (max-width: 640px) {
// //     width: 90%; /* Adjust width for smaller screens */
// //   }

// //   @media (max-width: 360px) {
// //     width: 95%; /* Further adjust width for very small screens */
// //   }
// // `;

// // const Input = styled.input`
// //   width: 100%;
// //   padding: 0.5rem; /* p-2 */
// //   border: 2px solid #d1d5db; /* border-2 border-gray-300 */
// //   border-radius: 0.375rem; /* rounded-md */
// //   margin-top: 0.5rem; /* mt-2 */
// // `;

// // const Button = styled.button`
// //   width: 100%;
// //   margin-top: 1.25rem; /* mt-5 */
// //   padding: 0.5rem; /* p-2 */
// //   background-color: #3b82f6; /* bg-custom-blue */
// //   color: #fff;
// //   border-radius: 0.375rem; /* rounded-md */
// // `;

// // const Recaptcha = styled.div`
// //   margin-top: 1rem;

// //   @media (max-width: 640px) {
// //     margin-top: 0.75rem; /* Adjust margin for smaller screens */
// //   }

// //   @media (max-width: 360px) {
// //     margin-top: 0.5rem; /* Further adjust margin for very small screens */
// //   }
// // `;

// // //getAuth().settings.appVerificationDisabledForTesting = true;

// // const db = getFirestore();

// // export const Auth = () => {
// //   const [phone, setPhone] = useState("+91");
// //   const [hasFilled, setHasFilled] = useState(false);
// //   const [otp, setOtp] = useState("");
// //   const [user, setUser] = useState(null);

// //   let navigate = useNavigate();

// //   useEffect(() => {
// //     window.recaptchaVerifier = new RecaptchaVerifier(
// //       "recaptcha",
// //       {
// //         size: "invisible",
// //         callback: (response) => {
// //           // reCAPTCHA solved allow signInWithPhoneNumber.
// //         },
// //       },
// //       auth
// //     );
// //   }, []);

// //   const handleSend = (event) => {
// //     event.preventDefault();
// //     setHasFilled(true);
// //     let appVerifier = window.recaptchaVerifier;
// //     // try {
// //       getAuth().settings.appVerificationDisabledForTesting = false;

// //       signInWithPhoneNumber(auth, phone, appVerifier)
// //         .then((confirmationResult) => {
// //           window.confirmationResult = confirmationResult;
// //         })
// //         .catch((error) => {
// //           console.error(error);
// //           alert("An error occurred while sending the OTP: " + error.message);
// //         });
// //     // } catch (error) {
// //     //   console.error(error);
// //     //   alert("An error occurred while initializing reCAPTCHA: " + error.message);
// //     // }
// //   };

// //   const verifyOtp = async (event) => {
// //     let otp = event.target.value;
// //     setOtp(otp);

// //     if (otp.length === 6) {
// //       let confirmationResult = window.confirmationResult;
// //       confirmationResult
// //         .confirm(otp)
// //         .then(async (result) => {
// //           let user = result.user;
// //           console.log(user);
// //           // alert('User signed in successfully');

// //           const docRef = doc(db, "users", user.uid);
// //           const docSnap = await getDoc(docRef);

// //           if (docSnap.exists()) {
// //             setUser(docSnap.data());
// //           } else {
// //             console.log("No such document!");
// //             await setDoc(docRef, {
// //               name: "",
// //               city: "",
// //               createdAt: new Date().toISOString(),
// //               expireAt: null,
// //               fcmTokens: null,
// //               id: user.uid,
// //               membershipId: null,
// //               mobile: user.phoneNumber,
// //             });
// //             setUser({ name: "", city: "", id: user.uid });
// //           }

// //           navigate("/auth-success");
// //         })
// //         .catch((error) => {
// //           alert("User couldn't sign in (bad verification code?)");
// //         });
// //     }
// //   };

// //   if (!hasFilled) {
// //     return (
// //       <Container>
// //         <Header className="dark:bg-gray-800">
// //           <HeaderContent>
// //             <div className="flex justify-start items-center">
// //               <Logo src={logo} alt="Thirdeye" />
// //             </div>
// //           </HeaderContent>
// //         </Header>

// //         <MainContent>
// //           <BlueBox>
// //             <h2>Register</h2>
// //           </BlueBox>
// //           <FormContainer>
// //             <form onSubmit={handleSend}>
// //               <Input
// //                 type="text"
// //                 placeholder="Phone Number"
// //                 value={phone}
// //                 onChange={(event) => setPhone(event.target.value)}
// //               />
// //               <Button type="submit">SEND OTP</Button>
// //             </form>
// //           </FormContainer>
// //           <Recaptcha id="recaptcha"></Recaptcha>
// //         </MainContent>
// //       </Container>
// //     );
// //   } else {
// //     return (
// //       <Container>
// //         <Header>
// //           <HeaderContent>
// //             <Logo src={logo} alt="Thirdeye"/>
// //           </HeaderContent>
// //         </Header>

// //         <MainContent>
// //           <BlueBox>
// //             <h2>Register</h2>
// //           </BlueBox>
// //           <FormContainer>
// //             <form onSubmit={handleSend}>
// //               <Input
// //                 type="text"
// //                 placeholder="Phone Number"
// //                 value={phone}
// //                 onChange={(event) => setPhone(event.target.value)}
// //               />
// //               <Input
// //                 type="text"
// //                 placeholder="OTP"
// //                 value={otp}
// //                 onChange={verifyOtp}
// //                 style={{ marginTop: "1.25rem" }} // Adjust for OTP input
// //               />
// //               <Button type="submit">Verify OTP</Button>
// //             </form>
// //           </FormContainer>
// //           <Recaptcha id="recaptcha" />
// //         </MainContent>
// //       </Container>
// //     );
// //   }
// // };
import { useState, useEffect, useRef } from "react";
import { auth } from "../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import logo from "../images/thirdeye.png";
import styled, { keyframes } from "styled-components";
import Backgroundimage from '../images/background.png'
import './auth.css'


// Your styled components...
const Container = styled.div`
 font-family: sans-serif;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 150px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${Backgroundimage}); // Apply background image
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.4; // Set the opacity to 40%
    z-index: -1; // Ensure the background is behind the content
  }

`;

const Header = styled.header`
  background-color: #fff; /* bg-white */
  border-bottom: 1px solid #e2e8f0; /* border-gray-200 */
  padding: 0.625rem 1rem; /* px-4 py-2.5 */
  poition:fixed;
  gap :10px;
  @media (min-width: 1024px) {
    padding: 0.625rem 1.5rem; /* lg:px-6 */
  }
  &.dark {
    background-color: #2d3748; /* dark:bg-gray-800 */
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 2.75rem; /* h-11 */
  width: auto;
  align-self: center;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Adjust height for better view on smaller screens */
  max-width: 90%; /* Ensure it doesn't overflow on smaller screens */
  width: 24rem; /* Default width */
  margin: 0 auto;
  margin-top: 0; /* mt-2 */
  padding: 0;
  //background-color: #f7fafc; /* bg-gray-100 */
  position: relative;
 // opacity: 100;

  @media (max-width: 640px) {
    width: 90%; /* Adjust width for smaller screens */
    height: 70vh; /* Adjust height for smaller screens */
  }

  @media (max-width: 360px) {
    width: 95%; /* Further adjust width for very small screens */
    height: 60vh; /* Further adjust height for very small screens */
  }
`;

const BlueBox = styled.div`
  width: 80%; /* w-4/5 */
  background-color: #3b82f6; /* bg-custom-blue */
  padding: 1.25rem; /* p-5 */
  border-radius: 0.5rem; /* rounded-lg */
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 640px) {
    width: 90%; /* Adjust width for smaller screens */
  }

  @media (max-width: 360px) {
    width: 95%; /* Further adjust width for very small screens */
  }
`;

const FormContainer = styled.div`
  width: 80%; /* w-4/5 */
  background-color: #fff;
  padding: 1.25rem; /* p-5 */
  border-radius: 0.5rem; /* rounded-lg */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
  margin-top: 1.25rem; /* mt-5 */
  transition: all 0.5s ease-in-out; /* transition duration-500 ease-in-out */
  transform: translateY(0);

  @media (max-width: 640px) {
    width: 90%; /* Adjust width for smaller screens */
  }

  @media (max-width: 360px) {
    width: 95%; /* Further adjust width for very small screens */
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem; /* p-2 */
  border: 2px solid #d1d5db; /* border-2 border-gray-300 */
  border-radius: 0.375rem; /* rounded-md */
  margin-top: 0.5rem; /* mt-2 */
`;

const Button = styled.button`
  width: 100%;
  margin-top: 1.25rem; /* mt-5 */
  padding: 0.5rem; /* p-2 */
  background-color: #3b82f6; /* bg-custom-blue */
  color: #fff;
  border-radius: 0.375rem; /* rounded-md */
`;

const Recaptcha = styled.div`
  margin-top: 1rem;

  @media (max-width: 640px) {
    margin-top: 0.75rem; /* Adjust margin for smaller screens */
  }

  @media (max-width: 360px) {
    margin-top: 0.5rem; /* Further adjust margin for very small screens */
  }
`;

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

const BlinkingText = styled.p`
  
  font-weight: bold;
`;

const h1Style = {
  textAlign: 'center',
   //fontSize: '48px',
  fontWeight: 700,
  textTransform: 'uppercase',
  color: ' #3b82f6',
  // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  letterSpacing: '2px',
  marginBottom: '20px',
  // position:'absolute',
  top:'0',
  '@media  (max-width: 1440px)': {  // Desktop
      fontSize: '48px',
    },
    '@media  (max-width: 1024px)': {  // Laptop
      fontSize: '36px',
    },
    '@media   (max-width: 768px)': {   // Tablet
      fontSize: '28px',
    },
    '@media  (max-width: 480px)': {   // Mobile
      fontSize: '22px',
    },
    '@media   (max-width: 320px)': {   // Mobile
      fontSize: '16px',
    },
};

const db = getFirestore();
// const auth = getAuth();

export const Auth = () => {
  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState(null);
  const recaptchaVerifierRef = useRef(null);
  const [isOtpSending, setIsOtpSending] = useState(false);
  const [timer, setTimer] = useState(30); // Initialize 30 seconds timer
  const [isTimerActive, setIsTimerActive] = useState(false); // Control timer state
  let navigate = useNavigate();

 /*  useEffect(() => {
    let countdown;
    if (isTimerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }

    return () => clearInterval(countdown);
  }, [isTimerActive, timer]);

  function checkverify() {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(
        "recaptcha",
        {
          size: "invisible",
          callback: (response) => {
            handleSend();
          },
          "expired-callback": () => {
            console.log("reCAPTCHA expired");
          },
        },
        auth
      );
    }
  }

  const handleSend = async (event) => {
    event.preventDefault();
    if (isOtpSending || isTimerActive) return;

    setIsOtpSending(true);
    setHasFilled(true);

    checkverify();
    const appVerifier = recaptchaVerifierRef.current;

    try {
      await signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setTimer(30); // Reset timer to 30 seconds
          setIsTimerActive(true); // Start the timer
        })
        .catch((error) => {
          console.error(error);
          alert("An error occurred while sending the OTP: " + error.message);
        });
    } catch (error) {
      console.error(error);
      alert("An error occurred while initializing reCAPTCHA: " + error.message);
    } finally {
      setIsOtpSending(false);
    }
  };

  const verifyOtp = async (event) => {
    event.preventDefault();
    let otp = event.target.value;
    setOtp(otp);

    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then(async (result) => {
          let user = result.user;
          console.log(user);

          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUser(docSnap.data());
          } else {
            console.log("No such document!");
            await setDoc(docRef, {
              name: "",
              city: "",
              createdAt: new Date().toISOString(),
              expireAt: null,
              fcmTokens: null,
              id: user.uid,
              membershipId: null,
              mobile: user.phoneNumber,
            });
            setUser({ name: "", city: "", id: user.uid });
          }

          navigate("/auth-success");
        })
        .catch((error) => {
          console.error("Verification failed: ", error);
          alert("User couldn't sign in (bad verification code?)");
        });
    }
  }; */

  /* const handleResendOtp = async () => {
    await handleSend(); // Resend the OTP
    setTimer(30); // Reset the timer
    setIsTimerActive(true); // Restart the timer
  }; */
  useEffect(() => {
    let countdown;
    if (isTimerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }
 
    return () => clearInterval(countdown);
  }, [isTimerActive, timer]);
 
  function checkverify() {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(
        "recaptcha",
        {
          size: "invisible",
          callback: (response) => {
            handleSend(); 
          },
          'expired-callback': () => {
            console.log("reCAPTCHA expired");
          }
        },
        auth
      );
    }
  }
 
  const handleSend = async (event) => {
     event.preventDefault();
    if (isOtpSending || isTimerActive) return;
 
    setIsOtpSending(true);
    setHasFilled(true);
 
    checkverify();
    const appVerifier = recaptchaVerifierRef.current;
 
    try {
      await signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setTimer(30); // Reset timer to 30 seconds
          setIsTimerActive(true); // Start the timer
        })
        .catch((error) => {
          console.error(error);
          alert("An error occurred while sending the OTP: " + error.message);
        });
    } catch (error) {
      console.error(error);
      alert("An error occurred while initializing reCAPTCHA: " + error.message);
    } finally {
      setIsOtpSending(false);
    }
  };
 
  const verifyOtp = async (event) => {
    event.preventDefault();
    let otp = event.target.value;
    setOtp(otp);
 
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then(async (result) => {
          let user = result.user;
          console.log(user);
 
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
 
          if (docSnap.exists()) {
            setUser(docSnap.data());
            navigate("/area")
          } else {
            console.log("No such document!");
            await setDoc(docRef, {
              name: "",
              city: "",
              createdAt: new Date().toISOString(),
              expireAt: null,
              fcmTokens: null,
              id: user.uid,
              membershipId: null,
              mobile: user.phoneNumber,
            });
            setUser({ name: "", city: "", id: user.uid });
             navigate("/auth-success");
          }
 
         
        })
        .catch((error) => {
          console.error("Verification failed: ", error);
          alert("User couldn't sign in (bad verification code?)");
        });
    }
  };
 
  const handleResendOtp = async () => {
    await handleSend(); // Resend the OTP
    setTimer(30); // Reset the timer
    setIsTimerActive(true); // Restart the timer
   
  };

  if (!hasFilled) {
    return (
      <Container>
        <Header className="dark:bg-gray-800">
          <HeaderContent>
            <div className="flex justify-start items-center">
              <Logo src={logo} alt="Thirdeye" />
            </div>
          </HeaderContent>
        </Header>

        <MainContent>
          <div style={{display:"none"}}>
            <h2
              className="mb-4 text-center font-bold font-sans-serif "
              style={{color:"#3b82f6" }}
              
            >
              YOU
            </h2>

            <div className="flex justify-center">
              <ul
                className="list-disc font-sans-serif"
                style={{ marginLeft: "45px",fontWeight:"inherit" ,color:"#3b82f6" }}
              >
                <li style={{color:"#3b82f6" }}>
                  Want to raise funds without selling your entire property?
                </li>
                <li style={{color:"#3b82f6" }}>Want to invest only a few rupees in real estate?</li>
              </ul>
            </div>
            <BlinkingText className="mt-4" style={{ textAlign: "center" , color:"#3b82f6" }}>
              The solution is here!
            </BlinkingText>
          </div>  
          <div style={h1Style}>
            <h1 >EVERYONE WILL BUY NOW</h1>
          </div>

          <BlueBox>
            <h2>Enter Mobile Number</h2>
          </BlueBox>
          <FormContainer>
            <form onSubmit={handleSend}>
              <Input
                type="text"
                placeholder="Phone Number"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
              <Button type="submit" disabled={isOtpSending || isTimerActive}>
                {isOtpSending ? "Sending..." : "SEND OTP"}
              </Button>
            </form>
          </FormContainer>
          <Recaptcha id="recaptcha"></Recaptcha>
        </MainContent>
        <div style={{ display: 'flex', justifyContent: 'center', gap:'10px'}}>
  <a 
    href="https://thirdeye.app/thirdeye-app-privacy-policy.html" 
    target="_blank" 
    
    className="privacy-link"
  >
    Privacy Policy
  </a>
  <Link to="/faq" className="privacy-link-2" >FAQ</Link>
  <p  className="privacy-link-2" onClick={()=>{window.open("https://youtu.be/oVV3E93nRKY","_blank")}}>Video</p>
</div>
      </Container>
    );
  } else {
    return (
      <Container>
        <Header>
          <HeaderContent>
            <Logo src={logo} alt="Thirdeye" />
          </HeaderContent>
        </Header>

        <MainContent>
        <div style={{display:"none"}}>
            <h2
              className="mb-4 text-center font-bold font-sans-serif"
              
            >
              YOU
            </h2>

            <div className="flex justify-center">
              <ul
                className="list-disc font-sans-serif"
                style={{ marginLeft: "45px",fontWeight:"inherit" }}
              >
                <li>
                  Want to get some money without selling your entire property?
                </li>
                <li>Want to invest only a few rupees in real estate?</li>
              </ul>
            </div>
            <BlinkingText className="mt-4" style={{ textAlign: "center" }}>
              The solution is here!
            </BlinkingText>
          </div> 
          <div style={h1Style}>
            <h1 >EVERYONE WILL BUY NOW</h1>
          </div>
         
          <BlueBox>
            <h2>Enter Mobile Number</h2>
          </BlueBox>
          <FormContainer>
            <form>
              <Input
                type="text"
                placeholder="Phone Number"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
              <Input
                type="text"
                placeholder="OTP"
                value={otp}
                onChange={verifyOtp}
                style={{ marginTop: "1.25rem" }}
              />
              <Button
                type="submit"
                onClick={verifyOtp}
                disabled={otp.length !== 6}
              >
                Verify OTP
              </Button>
              {timer === 0 && (
                <Button type="submit" onSubmit={handleResendOtp}>
                  Resend OTP
                </Button>
              )}
              {isTimerActive && <p>Resend OTP in {timer} seconds</p>}
            </form>
          </FormContainer>
          <Recaptcha id="recaptcha" />
        </MainContent>
        <div style={{ display: 'flex', justifyContent: 'center' ,gap:'10px' }}>
  <a 
    href="https://thirdeye.app/thirdeye-app-privacy-policy.html" 
    target="_blank" 
    style={{ textDecoration: 'none'  }} 
    className="privacy-link-2"
  >
    Privacy Policy
  </a>
  <Link to="/faq"  className="privacy-link-2">FAQ</Link>
  <p className="privacy-link-2" onClick={()=>{window.open("https://youtu.be/oVV3E93nRKY","_blank")}}>Video</p>
</div>
      </Container>
    );
  }
};
