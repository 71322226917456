import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from './components/auth';
import AuthSuccess from './components/authsucc';
import Dashboard from './components/dashboard';
import { auth } from "./config/firebase";
import AddProperty from './components/addproperty';
import Area from './components/area';
import CustomerDashBoard from './components/customerdashboard';
import Home from './components/home';
import Landing from './components/landing'
import FAQ from './components/FAQ';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/auth-success" element={<AuthSuccess />} />
        <Route path="/addproperty" element={<AddProperty />} />
        <Route path="/dashboard" element={user ? <Dashboard user={user} /> : null} />
        <Route path='/area' element={<Area/>}/>
        <Route path='/customerdash' element={user ? <CustomerDashBoard User={user} /> : null}/>
        {/* <Route path='/home' element={<Home/>}/>
        <Route path='/landing' element={<Landing/>}/> */}
        <Route path='/faq' element={<FAQ/>}/>
      </Routes>
    </Router>
  );
}

export default App;
