import React, { useState } from 'react';
import { FAQContainer, QuestionWrapper, Question, Answer, Icon, Heading, Header, HeaderContent, Logo, Faqmain } from './Faq.style';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './Faq.style'
import logo from "../images/thirdeye.png";



const Faq = () => {
  
  const [activeIndex, setActiveIndex] = useState(null);
 
  const faqs = [
    {
      question: "How does ThirdEye's digital asset sale work?",
      answer: "The seller lists their property for sale on the platform with specific offer dates, rates, and target amounts. Buyers can purchase land shares online, and the platform handles all verifications."
    },
    {
      question: "What is the minimum amount of land I can buy?",
      answer: "You can purchase any number of units of land, depending on your interest. There's no strict limit on the minimum or maximum amount of land to buy."
    },
    {
      question: "How do I pay for the land share?",
      answer: "Buyers can pay online through the platform using secure payment gateways. Once payment is made, your funds are reserved."
    },
    {
      question: "Can I cancel my purchase?",
      answer: "Yes, you can cancel your purchase anytime during the offer period. Your funds will be returned to you if the target is not reached or upon cancellation."
    },
    {
      question: "What happens if the target sales amount is reached?",
      answer: "If the target is reached, the seller receives the funds, and you, as a buyer, can either resell your land share or officially register it under your name."
    },
    {
      question: "What happens if the target sales amount is not reached?",
      answer: "If the target is not reached within the offer period, all buyers will be refunded automatically."
    },
    {
      question: "What is the post-sale support offered?",
      answer: "ThirdEye offers post-sale services such as assistance with legal documentation, ownership registration, and community support to ensure a smooth transition to ownership."
    },
    {
      question: "How does the community group work?",
      answer: "After purchasing, you will be part of a community group of all landowners, allowing you to stay informed and connected with others who share ownership in the project."
    },
    {
      question: "What are the benefits of bulk purchases?",
      answer: "Buyers who purchase larger quantities of land shares may qualify for bigger discounts, allowing you to maximize your savings."
    },
    {
      question: "What’s the expected value appreciation of the land?",
      answer: "The land is projected to appreciate by 50% to 300% over 2-7 years, making it a valuable long-term investment."
    },
    {
      question: "Where can I buy land shares?",
      answer: "You can make your purchase online at realestate.thirdeye.app during the sale period."
    },
    {
      question: "How can I get in touch with support?",
      answer: "You can contact the ThirdEye support team via email at contact@saralam.tech or call +9140 48597477 for assistance."
    }
  ];
 
  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
 
  return (<Faqmain className='faq-main'>
    <Header className="dark:bg-gray-800">
          <HeaderContent>
            <div className="flex justify-start items-center">
              <Logo src={logo} alt="Thirdeye" />
            </div>
          </HeaderContent>
        </Header>
    <FAQContainer>
        <Heading>ThirdEye Digital Assets Platform</Heading>
        <Heading>FAQ</Heading>
      {faqs.map((faq, index) => (
        <div key={index}>
          <QuestionWrapper onClick={() => toggleFAQ(index)}>
            <Question>{faq.question}</Question>
            <Icon>{activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}</Icon>
          </QuestionWrapper>
          {activeIndex === index && <Answer>{faq.answer}</Answer>}
        </div>
      ))}
    </FAQContainer>

    </Faqmain >
  );
};
 
export default Faq;